.react-calendar{
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.react-calendar__month-view__days{
  height: calc(100vh - 310px);
}

.react-calendar__tile {
  height: 20%;
  border-right: 1px solid #cddbe2 !important;
  border-top: 1px solid #cddbe2 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: block;
  overflow-y: auto !important;
}

.react-calendar__tile:nth-child(7n) {
  border-right: none !important;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
  cursor: auto !important;
  background-color: inherit;
}

.teamup-event{
  background-color: #b2dfdb;
  cursor: pointer;
  padding: 2px 8px;
  margin: 0 auto 4px;
  border-radius: 10px;
  transition: background-color 0.2s ease;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .teamup-event{
    font-size: 11px;
    width: 100%;
    padding: 2px 2px;
  }
}

.teamup-event:hover{
  background-color: #80cbc4;
}

.teamup-event.past{
  background-color: #e0f2f1;
  color: #b7c3c9;
}

.teamup-event.past:hover{
  background-color: #b2dfdb;
}

.react-calendar__tile--active {
  background: white;
  color: black;
}

.react-calendar__tile--now{
  background: white !important;
  color: black;
}

abbr{
  margin: 0 auto 5px;
}

.react-calendar__tile--now abbr{
  border-radius: 20px;
  display: block;
  width: 20px;
  margin: 0 auto 5px;
  padding: 3px;
  color: white;
  background-color: #1976d2;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:hover{
  background-color: #26a69a;
  color: white;
}

.react-calendar__month-view__weekdays{
  text-decoration: underline;
}

.react-calendar__navigation button{
  border-bottom: 1px solid #cddbe2;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:hover{
  background-color: transparent;
  color: #26a69a;
}